@import '../../../styles/colors.scss';

.CAKE__image-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 205px;
  height: 205px;
  max-width: 205px;
  border: 1px solid $border;
  color: $font-subtitle;
  font-size: 32px;
  border-radius: 20px;

  position: relative;

  &--small {
    width: 60px;
    height: 60px;
    max-width: 60px;
    border-radius: 14px;
    font-size: 28px;
  }

  &--active {
    opacity: 0.5;
  }

  &__info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    width: inherit;
    height: inherit;
    background-color: white;
    border-radius: 20px;
    transition: opacity 0.2s;

    &--small {
      border-radius: 14px;
      padding-top: 0;
    }

    &--with-image {
      opacity: 0.8;
    }

    &--hidden {
      opacity: 0;
    }
  }

  &__title {
    padding-top: 12px;
  }

  &__image {
    position: absolute;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    max-height: 90%;
    aspect-ratio: auto;
  }

  &__upload {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 16px;
    width: inherit;
    height: inherit;
    background-color: white;
    border-radius: 20px;
    transition: opacity 0.2s;

    &--small {
      padding-top: 0;
    }
  }
}