.CAKE__collection-item-modal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 750px;
  width: 750px;
  max-height: 700px;
  height: 700px;
  border-radius: 4px;
  padding: 16px;

  &__header {
    padding-bottom: 8px;
  }

  &__content {
    height: calc(100% - 50px);
  }

  &__footer {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 8px;
  }
}