@import '../../../../../styles/colors.scss';
@import '../../../../../styles/fontSizes.scss';

.CAKE__collection-item {
  border-radius: 4px;
  background-color: white;
  height: 340px;
  position: relative;

  &__description {
    position: absolute;
    height: 90px;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: $s;

    > div {
      width: 100%;
      padding-top: 18px;
      padding: 8px;
      cursor: default;
    }
  }

  &__errors {
    position: absolute;
    top: 10px;
    right: 10px;
    width: fit-content;
    flex-direction: row;
    display: flex;
    gap: 4px;
    color: red;
  }

  &__selection {
    position: absolute;
    left: 0;
    top: 0;
  }
}