$primary: #000000;
$secondary: #666666;

$primary-inverted: #FFFFFF;
$primary-teal: hsl(174, 100%, 29%);
$secondary-teal: #b2dfdb;

$disabled: #AAAAAA;
$disabled-transparent: #AAAAAA44;

$background: #e5f7f6;
$card-shadow: #004d4022;

$input-border: #b9e7e5;
$input-title: #26a69a;
$input-text: #00695c;
$input-error-border: #f44336;
$input-error-title: #df281b;
$input-error-text: #f66156;

// New Design
$border: rgb(0,0,0, 0.15);
$button-background-active: rgba(239, 239, 239, 0.75);
$button-background-hover: rgba(239, 239, 239);
$button-font: #656565;
$button-font-active: #1A1D1F;

// fonts
$font-header-gray: #8D8D8D;
$font-header: #272B30;
$font-subtitle: #131313;
$v2-background: #E7E7E7;
$v2-primary: #599CFF;
$v2-input-border: #b9e7e5;
$v2-input-title: #26a69a;
$v2-input-text: #00695c;
