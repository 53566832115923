@import '../../../styles/colors.scss';

.CAKE__wallet-distribution-chart {

  &__dashboard-card{
    display: flex;
    justify-content: space-between;
  }

  &__top-holders-table {

    &__container {
      width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &--link{
      text-decoration: none;
      color: inherit  
    }
  }
}