.CAKE__loader {

  &__container {    
    width: 100%;
    height: 100%;
    background: url(../../../../public/background.png) no-repeat center fixed;   
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;    
  }

  &__image {

    &--container {
      position: relative;
      display: inline-flex 
    }

    &--logo {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__title {
    color: white
  }

  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3.5rem;
    text-align: center;
    color: white;
    font-size: 10px;
  }
  
}