@import '../../../styles/colors.scss';

.CAKE__color-picker__swatch {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 157px;
  height: 157px;
  padding: 24px;
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid $border;
}