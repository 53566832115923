@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';

.CAKE__v2-text-input {
  width: 100%;
  
  &--noborder {
    border: none;

    fieldset {
      border-color: transparent !important;
    }

    .Mui-error {
      input {
        color: $input-error-text;
      }
    }
  }

  &--right {
    input {
      text-align: right;
      padding-right: 1rem;
    }
  }

  .MuiInputBase-sizeSmall {
    font-size: $m;
  }
}