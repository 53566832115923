@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__file-dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 3px dashed $primary-teal;
  color: $primary-teal;
  font-size: $l;
  font-weight: 600;
  background-color: $secondary-teal;

  transition: 300ms all linear;

  &--active {
    background-color: $primary-teal;
    border: 3px solid $primary-teal;
    color: $secondary-teal;
    height: 150px;
  }
}