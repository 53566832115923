@import '../../../../../styles/colors.scss';

.CAKE__project-drawer-menu-button {
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: none;
  border-radius: 8px;
  height: 48px;
  gap: 12px;
  padding: 0 12px;
  cursor: pointer;

  color: $button-font;
  font-size: 14px;
  font-weight: 500;
  clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);

  transition: 0.7s all 0.5s;
  transition: 0.3s background-color;

  &__icon {
    min-width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > p {
    white-space: nowrap;
    opacity: 1;
    width: fit-content;
    transition: 0.7s all 0.5s;
  }

  &--hidden {
    > p {
      opacity: 0;
    }
  }

  &:hover {
    background-color: $button-background-hover;
  }

  &--active {
    background-color: $button-background-active;
    color: $button-font-active;
    font-weight: 700;
  }
}