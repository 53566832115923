@import '../../../../../styles//colors.scss';

.CAKE__collection-viewer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid $primary-teal;

  &__left-container {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__right-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    gap: 8px;
  }
}