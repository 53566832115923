@import '../../../../../styles/colors.scss';

.CAKE__collection-item-content {
  height: 100%;

  &__top-container {
    display: flex;
    flex-direction: row;
  }

  &__image {
      width: 30%;
      height: 300px;
      position: relative;
      &--no {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid $disabled-transparent;
        border-radius: 4px;
      }
  }
  &__properties {
    padding-top: 8px;
    padding-left: 32px;
    width: 63%;
    > * {
      padding-bottom: 16px;
    }

    &__chips {
      display: flex;
      gap: 4px;
    }
  }

  &__traits {
    height: calc(100% - 300px);

    &__title-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 12px;
    }
    &__item {
      cursor: pointer;
    }
  }
}