@import '../../../styles/colors.scss';

.CAKE__contract-transaction-table__card-content {
  padding: 0;

  .inner-content {
    min-width: 1050;
    overflow: auto;
  }

  &--link {
    text-decoration: none;
    color: inherit;
  }

  &--type-pill{
    padding: 5px 10px;
    text-align: center;
    color: white;    
    border-radius: 12px;
    background-color: grey;

    &--isMint {
      background-color: green;
    }
  }

  .actions {
    justify-content: flex-end;
  }

}

.CAKE__contract-transaction-table__card-header {
  margin-top: 20px;
  padding: 0 16px 10px 24px;
  display: flex;
  justify-content: flex-end;
}

.CAKE__contract-transaction-table__default-content {
  margin-top: 20px;
  padding: 0 16px 10px 24px;
  display: flex;
  justify-content: center;
}