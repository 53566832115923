.CAKE__collection-artworks-card {
  width: 100%;
  height: calc(100% - 20px);

  &__container {
    padding: 0;
    height: 100%;
  }

  &__inner-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  &__dropzone-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 6px;
  }

  &__viewer-container {
    position: absolute;
    top: 0;
    bottom: 56px;
    left: 0;
    right: 0;
  }
}