.CAKE__gallery-image {
  position: relative;
  &__image {
    object-fit: cover;
    width: 205;
    height: 205px;
    max-width: 205px;
    border-radius: 20px;
    opacity: 1;

    &--mouseover {
      transition: 400ms;
      opacity: 0.3;
    }
  }
  &__delete {
    cursor: pointer;
    position: absolute;
    top: 4px;
    left: 4px;
    opacity: 0;
    z-index: 2;

    &--mouseover {
      transition: 400ms;
      opacity: 1;
    }
  }
}