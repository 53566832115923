@import '../../../../../styles/colors.scss';

.CAKE__project-drawer-footer {
  height: 90px;
  border-top: 1px solid $border;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 20px;
  width: 206px;
  transition: 0.7s all 0.5s;

  &--hidden {
    width: 48px;
  }

  &__side-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  &__avatar {
    margin: 0 4px;
  }

  &__name {
    cursor: default;
  }

  &__element {
    transition: 0.7s all 0.5s;

    &--hidden {
      opacity: 0;
    }
  }
}
