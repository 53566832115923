@import '../../../styles/fontSizes.scss';
@import '../../../styles/colors.scss';

.CAKE__auto-complete-single-select {

  &__title {
    font-size: $xs;
    padding-left: 8px;
    padding-bottom: 3px;
    color: $input-title;
  }

  &__error-message{
    color: $input-error-text;
    font-size: $s;
  }
}