.CAKE__login-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;

  h1, span {
    text-align: center;
  }
}