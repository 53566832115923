@import '../../../styles/colors.scss';
@import '../../../styles/fontSizes.scss';

.CAKE__editable-text-field {
  position: relative;

  &__title {
    font-size: $m;
    padding-bottom: 8px;
    color: $font-subtitle;

    &--error {
      color: $input-error-title;
    }

    &--standard {
      font-size: $s;
      color: $font-header-gray;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    border: 1px solid $border;
    // max-width: 100%;
    height: fit-content;
    min-height: 60px;
    position: relative;

    &--standard {
      border: none;
      border-bottom: 1px solid $border;
      min-height: 20px;
      padding: 0;
      padding-bottom: 2px;
    }

    &--error {
      border: 1px solid $input-error-border;
    }

    &--dense {
      border: none;
      min-height: 20px;
    }

    &__input {
      max-width: 100%;
      overflow: hidden;
      color: $font-subtitle;
      font-size: $m;
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: none;
      font-family: inherit;
      font-weight: 500;

      &:focus {
        outline: none;
        border: none;
      }

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        opacity: 0.5; /* Firefox */
      }

      &--right {
        text-align: right;
      }

      &--multiline {
        margin: 9px 0;
        padding: 0;
        overflow: auto;
        resize: none;
        height: auto;
      }

      &--error {
        color: $input-error-text;
      }
    }
  }

  &__right-container {
    position: absolute;
    right: 20px;
    bottom: 1px;
    top: 0px;
    width: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
  }

  &__error-message {
    font-size: $xs;
    padding-left: 8px;
    padding-top: 2px;
    color: $input-error-title;
    position: absolute;
    bottom: -12px;

    &--dense {
      position: absolute;
      left: 3px;
      bottom: -16px;
    }
  }
}

textarea::-webkit-scrollbar {
  width: 4px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #80cbc4;
  border-radius: 2px;
}